.annImage {
    margin: 16px 60px;
    width: 480px;
}

.annContainer {
    padding-top: 10px;
}

.metaBlockContainer {
    margin: 15px 0;
}